import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import PrimaryButton from "../components/primary-button"
import AwardsCeremonyVideo from "../components/awards-ceremony-video"

const AboutPage = () => (
  <Background>
    <Layout>
      <Seo title="About" />
      <Container>
        <PageTitle text="About" />
        <div style={{
          textAlign: `center`,
          backgroundColor: `rgba(0,0,0,0.6)`,
          paddingBottom: `4rem`
        }}>
          <div style={{
            maxWidth: `34rem`,
            margin: `auto`,
            padding: `5rem 2rem 1rem 2rem`
          }}>
            <span style={{
              display: `inline`,
              backgroundColor: `#d23464`,
              padding: `.5rem 0`,
              lineHeight: `3.4rem`,
              boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
              textTransform: `uppercase`,
              color: `#fff`,
              fontFamily: `Din Pro`,
              fontWeight: `700`,
              fontSize: `1.6rem`
            }}>Our Story</span>
          </div>
        </div>
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-sm">
                <p>In partnership with the Civil Service Smarter Working Programme, our leading event for the public sector celebrates innovation, collaboration and excellence. It is a platform to recognise the significant achievements made towards Smarter Working and the commitment demonstrated by individuals, teams and organisations to drive positive change.</p>
                <p>The concept of Smarter Working Live is not new. The Public Sector Paperless Awards (2017-2019), hosted by GovNews, merged with the Smarter Working Awards, in partnership with the Government Property Agency, and from here, Smarter Working Live was born.</p>
                <p>In 2020, we hosted the first Smarter Working Live Conference and Awards in a fully remote capacity, with over 1100 delegates from across the public sector. We received over 250 nominations from public sector organisations across 18 awards, and it was a huge success.</p>
                <p>The next event was even bigger and went hybrid, whereby our online conference spanned over 3 days and comprised of keynote speakers, workshops, plenary discussions and endless opportunities for networking, pioneering valuable content, timely debate and sector insights that will shape the future role of Smarter Working in the public sector.</p>
              </div>
              <div className="col-sm">
                
                <p>Last year, the event was even bigger and went hybrid, whereby our online conference spanned over 3 days and comprised of keynote speakers, workshops, plenary discussions and endless opportunities for networking, pioneering valuable content, timely debate and sector insights that will shape the future role of Smarter Working in the public sector.</p>
                <div style={{
                  border:`2px solid white`,
                  padding: `1rem`
                }}>
                <p><strong>In keeping with the ethos of Smarter Working, the next event will again provide that hybrid experience and will consist of:</strong></p>
                <ul>
                  <li>The online Smarter Working Live Conference Day</li>
                  <li>The Smarter Working Live Awards Ceremony</li>
                  <li>1 month of online GovNews workshops, seminars and networking</li>
                </ul>
                </div>
                <div className="py-4"><PrimaryButton to="/categories/" text="View the Award Categories" /></div>
              </div>
            </div>
          </Container>
        </div>
        <div style={{
        // maxWidth: `968px`,
        margin: `5rem auto`
      }}>
        {/* <div className="my-5">
          <AwardsCeremonyVideo title="2020 Awards Ceremony" />
        </div> */}
      </div>
        <div style={{marginTop: `100px`}}></div>
      </Container>
    </Layout>
  </Background>
)

export default AboutPage
